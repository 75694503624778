





import SimpleMDE from 'simplemde'
import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'

import { Inject } from '../../../extensions'

import {
  REFRESH_MDE_EVENT,
  SIMPLEMDE_CONFIG,
  SIMPLEMDE_SIMPLE_CONFIG
} from './MarkdownEditor.config'

/**
 * @emits blur - When the editor looses the :focus.
 * @emits dirty - When the editor's content gets changed for the 1st time since initialization.
 * @emits update:model - When the `_model` property gets updated.
 *
 * TODO: Mock the IoC Container for the Storybook to avoid getting errors.
 *
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl> (edited)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 */
@Component<MarkdownEditor>({
  name: 'MarkdownEditor',
  components: {
    VueSimpleMDE: () => import(
      /* webpackChunkName: "cms/molecules/MarkdownEditor" */
      'vue-simplemde'
    )
  },

  mounted (): void {
    this.handleRefresh()
  }
})
export class MarkdownEditor extends Vue {
  /**
   * Configuration of the `<VueSimpleMDE>` component.
   */
  @Prop({ type: Object, required: false })
  private readonly initConfig!: AnyObject

  /**
   * The value for the text field. Synced via `.sync` modifier.
   */
  @PropSync('model', { type: String, required: true })
  public _model!: string

  /**
   * Determines whether SimpleMDE should have basic configuration or extended
   */
  @Prop({ type: Boolean, required: false, default: false })
  private simple!: boolean

  @Inject(EventbusType)
  private readonly eventBus!: IEventbus

  // TODO: Mock the IoC Container for the Storybook to avoid getting errors.
  // @Inject(ModalType)
  // private readonly modalConnector!: IModal

  /**
   * SimpleMDE instance
   */
  private editor: SimpleMDE | null = null

  /**
   * Determines whether the `_model` property has been modified since the component got mounted.
   *
   * @see _model
   */
  private isDirty: boolean = false

  /**
   * Configuration of the `<VueSimpleMDE>` component.
   */
  public get config (): AnyObject {
    let initConfig: AnyObject

    if (this.initConfig) {
      initConfig = this.initConfig
    } else {
      initConfig = this.simple ? SIMPLEMDE_SIMPLE_CONFIG : SIMPLEMDE_CONFIG
    }

    // const modalConnector = this.modalConnector

    return {
      ...initConfig
      // modalConnector
    }
  }

  /**
   * Handles editor's refreshing
   */
  private handleRefresh (): void {
    this.eventBus.handle(REFRESH_MDE_EVENT, () => {
      if (!this.editor) {
        return
      }

      this.editor.codemirror.refresh()
    })
  }

  /**
   * Marks the component as dirty.
   */
  private markAsDirty (): void {
    if (!this.isDirty) {
      this.$emit('dirty')
    }

    this.isDirty = true
  }

  /**
   * Handles the @initialized event on the <VueSimpleMDE> component.
   *
   * @param editor - An instance of the SimpleMDE editor
   */
  public onSimpleMDEInit (editor: SimpleMDE): void {
    this.editor = editor
  }

  /**
   * Watches the `_model` property and reacts when it's being updated.
   *
   * @see _model
   */
  @Watch('_model')
  private onModelChange (): void {
    this.markAsDirty()
  }
}

export default MarkdownEditor
