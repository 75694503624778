// Copyright © 2021 Move Closer

import { guideShortcut, headingShortcut } from './shortcuts'

/**
 * Event name for refreshing `VueSimpleMDE`
 */
export const REFRESH_MDE_EVENT: string = 'ui:MarkdownEditor.refresh'

/**
 * Base `<VueSimpleMDE>` configuration
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
const baseConfig = {
  autoDownloadFontAwesome: false,
  indentWithTabs: false,
  spellChecker: false,
  tabSize: 4
}

/**
 * Configuration of the `<VueSimpleMDE>` component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 *
 * @see https://github.com/F-loat/vue-simplemde/blob/master/doc/configuration_en.md
 */
export const SIMPLEMDE_CONFIG = {
  ...baseConfig,
  toolbar: [
    'bold', 'italic', 'strikethrough',
    '|', headingShortcut, 'unordered-list', 'ordered-list',
    '|', 'preview', 'side-by-side', 'fullscreen',
    '|', guideShortcut
  ]
}

/**
 * Simplified configuration of the `<VueSimpleMDE>` component.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const SIMPLEMDE_SIMPLE_CONFIG = {
  ...baseConfig,
  toolbar: ['bold', 'italic', 'strikethrough']
}
